import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SuccessStory from "../../components/success-stories/SuccessStory"

const SuccessStoryPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SuccessStory data={data} />
    </Layout>
  )
}

export const query = graphql`
  query OneAndAll($id: String = "") {
    mdx(id: { eq: $id }) {
      frontmatter {
        city
        country
        date
        headline
        level
        name
        niche
        rating
        state
        summary
        title
        youtube_id
        youtube_title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        image_alt
      }
      body
      slug
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt
          city
          country
          level
          name
          first_name
          niche
          rating
          state
          headline
          summary
          youtube_id
          youtube_title
        }
        id
        slug
      }
    }
  }
`
export default SuccessStoryPage
