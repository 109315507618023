import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { NavigationBar } from "../main/NavigationBar"
import Dropdown from "../main/Dropdown"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"
import { AiFillStar } from "react-icons/ai"
import favicon from "../../assets/images/nlc-favicon.png"
import SuccessStories from "../success-stories/SuccessStories"

export default function SuccessStory({ data }) {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const title = "Success Story | " + data.mdx.slug

  return (
    <>
      <Helmet
        title={title}
        meta={[
          {
            name: "description",
            content:
              "The Best Online Courses For Learning Content Creation. Commit to daily growth by enrolling in immersive, online classes with the worlds best instructors. Unlock your creative potential.",
          },
          { name: "keywords", content: "...." },
        ]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <NavigationBar toggle={toggle} />
      <section className="themes__panel-light">
        <SeparationWrapper>
          <SuccessStoryWrapper>
            <SuccessStoryContainer>
              <ClientlVideoContainer>
                <ClientVideoInnerSmall>
                  <iframe
                    width="460"
                    height="249"
                    className="success-story-modal__video"
                    src={
                      "https://www.youtube.com/embed/" +
                      data.mdx.frontmatter.youtube_id
                    }
                    title={data.mdx.frontmatter.youtube_title}
                    style={{ border: "0" }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </ClientVideoInnerSmall>
                <ClientVideoInnerLarge>
                  <div className="iframe-container">
                    <iframe
                      width="560"
                      height="315"
                      className="success-story-modal__video"
                      src={
                        "https://www.youtube.com/embed/" +
                        data.mdx.frontmatter.youtube_id
                      }
                      title={data.mdx.frontmatter.youtube_title}
                      style={{ border: "0" }}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </ClientVideoInnerLarge>
              </ClientlVideoContainer>
              <CtaButtonContainer>
                <Link to="/case-study1" style={{ textDecoration: "none" }}>
                  <CtaButtonInner>
                    <span>Watch Free Training</span>
                  </CtaButtonInner>
                </Link>
              </CtaButtonContainer>
              <HeadlineContainer>
                <Title>{data.mdx.frontmatter.headline}</Title>
              </HeadlineContainer>
              <RatingContainer>
                <span className="success-story-modal__label">Rating:</span>
                <ClientRating>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </ClientRating>
              </RatingContainer>
              <NameLevelContainer>
                <NameLevelInnerContainer>
                  <div>
                    <span className="success-story-modal__label">Name:</span>
                    <span>{data.mdx.frontmatter.name}</span>
                  </div>
                </NameLevelInnerContainer>
              </NameLevelContainer>
              <NicheContainer>
                <div>
                  <span className="success-story-modal__label">Niche:</span>
                  <span>{data.mdx.frontmatter.niche}</span>
                </div>
              </NicheContainer>
              <LocationContainer>
                <div>
                  <span className="success-story-modal__label">Location:</span>
                  <span>
                    {data.mdx.frontmatter.city}
                    {data.mdx.frontmatter.city ? ", " : ""}
                    {data.mdx.frontmatter.state}
                    {data.mdx.frontmatter.state ? ", " : ""}
                    {data.mdx.frontmatter.country}
                  </span>
                </div>
              </LocationContainer>
              <DescriptionContainer>
                <div className="success-story-modal__label">Description:</div>
                <div>{data.mdx.frontmatter.summary}</div>
              </DescriptionContainer>
              <TranscriptContainer>
                <div className="success-story-modal__label">Transcript:</div>
                <div>{<MDXRenderer>{data.mdx.body}</MDXRenderer>}</div>
              </TranscriptContainer>
            </SuccessStoryContainer>
          </SuccessStoryWrapper>
          <SuccessStories data={data} />
        </SeparationWrapper>
      </section>
    </>
  )
}

const SeparationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, auto);
`
const SuccessStoryWrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(auto, auto);
`
const SuccessStoryContainer = styled.div`
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 20px;
  padding-top: 125px;
  display: grid;
  grid-template-columns: 1fr, 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-template-areas:
    "close close"
    "video title"
    "video rating"
    "video nameLevel"
    "video niche"
    "video location"
    "cta description"
    "link description"
    "transcript transcript";
  max-width: 1060px;
  min-width: 952;
  background-color: #fff;

  @media (max-width: 1030px) {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(auto, auto);
    grid-template-areas:
      "close "
      "title"
      "video"
      "cta"
      "link"
      "rating"
      "nameLevel"
      "niche"
      "location"
      "description"
      "transcript";

    height: auto;
    width: 90%;
    margin: 5px;
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    width: 100%;
    padding: 3px;
    padding-top: 100px;
    padding-bottom: 30px;
  }
`
const ClientlVideoContainer = styled.div`
  padding-bottom: 40px;
  width: 100%;
  grid-area: video;
`
const ClientVideoInnerSmall = styled.div`
  display: none;
  @media (max-width: 1030px) {
    display: none;
  }
`
const ClientVideoInnerLarge = styled.div`
  @media (max-width: 1030px) {
    display: flex;
    justify-content: center;
    display: block;
    width: auto;
  }
`
const CtaButtonContainer = styled.div`
  margin: 0;
  padding: 0;
  grid-area: cta;
`
const CtaButtonInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(123, 46%, 34%);
  border-color: hsl(123, 46%, 14%);
  color: white;
  height: 50px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-color: hsl(123, 46%, 34%);
    box-shadow: 0 0 5px 0 hsl(123, 46%, 24%);
  }
`
// const TestimominalLinkContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 10px;
//   grid-area: link;

//   @media (max-width: 550px) {
//     margin-bottom: 30px;
//   }
// `
const HeadlineContainer = styled.div`
  padding: 0 20px;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-area: title;

  @media (max-width: 1030px) {
    padding-bottom: 20px;
  }
`
const Title = styled.h1`
  text-align: left;
  font-size: 26px;
  font-weight: 700;

  @media (max-width: 1030px) {
    text-align: center;
  }
`
const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 30px 20px;
  grid-area: rating;

  @media (max-width: 550px) {
    padding-bottom: 20px;
  }
`
const ClientRating = styled.span``

const NameLevelContainer = styled.div`
  grid-area: nameLevel;
`
const NameLevelInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 1030px) {
    display: block;
  }
`
const NicheContainer = styled.div`
  padding-left: 20px;
  grid-area: niche;
`
const LocationContainer = styled.div`
  padding-left: 20px;
  grid-area: location;
`
const DescriptionContainer = styled.div`
  padding: 0 20px;
  width: 100%;
  grid-area: description;
`
const TranscriptContainer = styled.div`
  grid-area: transcript;
  padding-bottom: 40px;
  @media (max-width: 550px) {
    padding: 20px;
  }
`
const StarIcon = styled(AiFillStar)`
  font-size: 20px;
  color: #ffc15b;
`
